import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

let recaptchaInstance: ReturnType<typeof useReCaptcha> | null = null

export default function useVueRecaptcha() {
  const {
    public: {
      recaptcha: { siteKey },
    },
  } = useRuntimeConfig()

  const nuxtApp = useNuxtApp()

  onMounted(() => {
    initRecaptcha()
  })

  function initRecaptcha() {
    if (!recaptchaInstance) {
      nuxtApp.vueApp.use(VueReCaptcha, {
        siteKey,
        loaderOptions: {
          autoHideBadge: true,
        },
      })

      recaptchaInstance = useReCaptcha()
    }
  }

  async function getCaptchaToken(action: string) {
    if (!recaptchaInstance) {
      initRecaptcha()
    }

    await recaptchaInstance?.recaptchaLoaded()
    return await recaptchaInstance?.executeRecaptcha(action)
  }

  return {
    getCaptchaToken,
  }
}
