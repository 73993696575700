<script setup lang="ts"></script>

<template>
  <div class="text-xs">
    {{ $t('base.form.captcha.protected') }}
    <a href="https://policies.google.com/privacy">
      {{ $t('base.form.captcha.privacy') }}
    </a>
    {{ $t('base.form.captcha.and') }}
    <a href="https://policies.google.com/terms">
      {{ $t('base.form.captcha.service') }}
    </a>
    {{ $t('base.form.captcha.apply') }}
  </div>
</template>

<style scoped></style>
